<template>
  <div class="packets">
    <v-container>
      <h1 class="subheading grey--text">House keeping packets</h1>
      <v-layout row wrap v-if="!isLoading">
        <v-flex xs12 sm12 pa-4>
          <div v-for="packet in packets" :key="packet._id">
            <!-- <PacketRow :packet="packet" /> -->
            <v-card flat class="pa-7 clickable">
              <v-layout row wrap>
                <v-flex xs12 sm6 md6 lg3 xl3>
                  <h3 class="mr-2"><v-icon>$satelliteDark</v-icon> {{packet.satellite?.name ? packet.satellite?.name : "Unknown"}}</h3>
                  <div>{{`${dateFormat(packet.serverTime)} (${dateSince(packet.serverTime)})`}}</div>
                </v-flex>
                <v-flex xs12 sm3 md3 lg2 xl2>
                  <div class="caption grey--text">Mode</div>
                  <div>{{`${packet?.satellite?.configuration?.mode}@${packet?.satellite?.configuration?.freq}`}}</div>
                </v-flex>
                <v-flex xs12 sm12 md12 lg7 xl7>
                  <div class="caption grey--text">Data</div>
                  {{ toHex(packet.data) }}
                </v-flex>
                <!-- <v-flex xs12 sm3 md3 lg2 xl2>
                  <div class="caption grey--text">Received by</div>
                  <div v-if="packet?.stationNumber > 1">{{ packet.stationNumber }} stations</div>
                  <div v-else>{{ packet?.stationNumber }} station</div>
                </v-flex> -->
              </v-layout>
            </v-card>
            <v-divider></v-divider>
          </div>
        </v-flex>
      </v-layout>
      <v-row v-else class="fill-height ma-0" align="center" justify="center">
        <v-progress-circular indeterminate color="grey"></v-progress-circular>
      </v-row>

      <!-- pagination -->
      <v-row justify="center">
        <v-col sm="12" md="6" lg="4">
          <v-container class="max-width">
            <v-pagination
              v-show="pagination?.pageCount > 1"
              v-model="page"
              :length="pagination?.pageCount || 1"
            ></v-pagination>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import io from 'socket.io-client';
import moment from 'moment';

import { useApi } from '../hooks/useApi';

import { dateToMyTimeZone } from '../utils/myDate';


const houseKeepingPacketAPI = useApi().houseKeepingPacket

export default {
  name:"Packets",
  components: {
    // PacketRow
  },
  data() {
    return {
      isLoading: true,
      packets: [],

      // pagination
      page: 1,
      limit: 10,
      pagination: {}
    }
  },
  beforeMount() {
    this.getHKPackets()
  },
  mounted() {
    // Connect to the Socket.io server
    // this.socket = io(process.env.VUE_APP_API_BASE_URL);

    // Listen for messages from the server
    // this.socket.on('GET_PACKETS', (msg) => {
    //   this.getHKPackets();
    // });
  },
  methods:{
    async getHKPackets() {
      this.isLoading = true;

      const { success, data, pagination } = await houseKeepingPacketAPI.get(this.page, this.limit);
      this.isLoading = false;
      if (success)
      {
        this.packets = data;
        this.pagination = pagination
      }
    },
    dateFormat(time) {
      return dateToMyTimeZone(time);
    },
    dateSince(time) {
      return moment(time).fromNow()
    },
    toHex(data ) {
      if (!data)
      {
        return packetData;
      }

      try
      {
        let packetData = "";
        let decodedData = Buffer.from(data, 'base64')
        for (var i = 0; i < decodedData.length; i++) {
            if (decodedData[i] <= 0xF) { packetData += "0"; }
            else { packetData += " "; }
            packetData += decodedData[i].toString(16) + " ";
        }
        return packetData;
      }
      catch (error) {
        return "";
      }
    }
  },
  watch: {
    page() {
      this.getHKPackets();
    }
  }
}
</script>
